import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts, receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';
import FilteredMasonry from './FilteredMasonry';

// wird nicht mehr gebraucht: Header soll ganz raus
// import MerchstandImages from './assets/images/MerchstandImages';

interface RouteParams {
  readonly id: string;
}

type Props = {
  /**
   * productFilter can be used if you want a Route to a dedicated page for special merch/products.
   *
   * Example:
   * You want a page only with merch that has the tag id 1234.
   * The function for productFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialmerch" render={()=> <ProductListing productFilter={p => p.tag_ids.includes(1234)} />} />
   */
  productFilter?: (p: Article) => any;
};

const Home: React.FC<Props> = ({ productFilter = (p) => p }) => {
  const { t } = useTranslation();
  const params = useParams<RouteParams>();
  const { products, loadingProducts, tickets, loadingTickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts, receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  const ticketFilter = (p) => !p.tag_ids.includes(7486);

  if (!productFilter && params?.id) {
    productFilter = (p) => p.category_ids.includes(Number(params.id));
  }

  React.useEffect(() => {
    receiveProducts();
    receiveTickets();
  }, [language]);

  return (
    <article className="mb-5">
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="landing-page" />
      </Helmet>
      <div className="list-page-highlights-wrapper">
        <div className="list-page-highlights">
          <div className="container list-page">
            {loadingProducts || loadingTickets ? ( // if loading
              <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
            ) : products.length === 0 ? ( // if no products
              <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
            ) : (
              // else show products
              <FilteredMasonry
                id="product-listing"
                products={products.filter(productFilter)}
                filters="off"
                // dontUpdateQueryStrings
                tagId={6239}
                showSubtitle
                columnClassName="col-12 col-md-6 col-lg-6"
                // itemClassName="random-rotation-object"
                // itemImageClassName="scribble-hover-overlay-object"
              />
            )}
          </div>
          <a href="/category/4104">
            <h2 className="mt-5 mb-5 text-center highlight-link">Weitere Bundles →</h2>
          </a>
        </div>

        <Container className="list-page-tickets">
          <h1 className="mt-5 mb-5 text-center">TICKETS</h1>

          {!loadingTickets && tickets.length === 0 ? ( // if no tickets
            <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
          ) : (
            // else show tickets
            !params.id && ( // but only when not filtered for product category
              <>
                <Masonry
                  id="ticket-listing"
                  products={tickets.filter(ticketFilter)}
                  type="list"
                  showCity
                  showLocation
                  showSubtitle
                />
              </>
            )
          )}
        </Container>
      </div>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products } = state.Products;
  const loadingProducts = state.Products.loading;
  const { tickets } = state.Tickets;
  const loadingTickets = state.Tickets.loading;
  return {
    products,
    loadingProducts,
    tickets,
    loadingTickets,
  };
};

const mapDispatchToProps = {
  receiveProducts,
  receiveTickets,
};

export default React.memo(Home);
